import useSectors from "@/hooks/useSectors";
import { Buttons } from "@/ui/Buttons";
import { Hexagon } from "@/ui/Hexagon";
import { ParallaxBackground } from "@/ui/ParallaxBackground";
import cn from "classnames";
import { useEffect, useState } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";

import bg_1 from "./bg_1.webp";
import bg_2 from "./bg_2.webp";
import classes from "./styles.module.scss";
import Vector1 from "./Vector1.svg";
import Vector2 from "./Vector2.svg";

export default function ({
  className = "py-5 pb-md-0 pt-lg-8",
  buttons = [{ label: "View All Sectors", url: "/expertise/", variant: "cyan" }],
  EditableText,
  theme = "light",
}) {
  const sectors = useSectors();
  const [active, setActive] = useState(sectors[0]);
  const [blurb, setBlurb] = useState(active.blurb);
  const [description, setDescription] = useState(active.description);
  const [image, setImage] = useState(active.image);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);

    const timer1 = setTimeout(() => {
      setDescription(active.description);
      setBlurb(active.blurb);
      setImage(active.image);
    }, 500);

    const timer2 = setTimeout(() => {
      setAnimate(false);
    }, 1000);

    () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [active]);

  return (
    <div className={cn(className, classes.AreasOfExpertise)} data-bs-theme={theme}>
      <img src={Vector2} alt="" className={classes.vector_2} />
      <ParallaxBackground anim="y2n:x0:z0" image={bg_1} className={classes.bg_1} />
      <ParallaxBackground anim="y1:x0:z0" image={bg_2} floating={false} className={classes.bg_2} />
      <Container fluid="xl">
        <Row>
          <Col xs={12} md={6} className="position-relative d-none d-md-block">
            <img src={Vector1} alt="" className={classes.vector_1} />
            <Hexagon
              className={cn(classes.description, {
                "animate-start": animate,
                "animate-end": !animate,
              })}
            >
              <div className="p-5 d-flex align-items-center">
                {/* <div className="px-5 small" dangerouslySetInnerHTML={{ __html: description }} /> */}
                <p className="px-5 small">{blurb}</p>
              </div>
            </Hexagon>
            <Hexagon
              className={cn(classes.image, {
                "animate-start": animate,
                "animate-end": !animate,
              })}
            >
              <img src={image} alt="" />
              <img src={active.image} alt="" />
            </Hexagon>
          </Col>
          <Col xs={12} md={6} className="text-white">
            <EditableText path="title" tag="h3" className="h6 fw-bold mb-4 mb-md-5">
              Our Areas of Expertise
            </EditableText>
            <Nav className="flex-column mb-5 mb-md-6 gap-2 gap-md-3">
              {sectors
                .filter(
                  (sector) =>
                    sector.name !== "Recruitment" && sector.name !== "Food / Beverage / FMCG",
                )
                .map((sector, idx) => (
                  <Nav.Item key={idx} className="">
                    <Nav.Link
                      className="py-0 h3"
                      onClick={() => setActive(sector)}
                      active={active.id === sector.id}
                    >
                      {sector.name}
                    </Nav.Link>
                    {active.id === sector.id && (
                      <div
                        className="d-block d-md-none px-3 pt-4"
                        dangerouslySetInnerHTML={{ __html: sector.blurb }}
                      />
                    )}
                  </Nav.Item>
                ))}
            </Nav>
            <Buttons buttons={buttons} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
